<template>
  <div class="sxx">
    <div class="left">
      <div class="title">项目介绍：</div>
      <div class="content">
        闪薪薪，以智能工资代发为产品核心，智能制单校验，全网实时发薪，工资秒级支付为优势，搭建了发薪、领薪、培训、电商等多种领域平台，打造出“企业一站式互联网薪酬管理”服务体系。为富士康、纬创、捷普等多家高新企业提供薪酬解决方案，助力企业数字化升级，让发薪更简单、不发愁！
      </div>
    </div>
    <div class="right displayF justify-contentC align-itemsC">
      <img src="../../assets/Group 31.png" alt="" class="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.sxx {
  width: 100%;
  height: 100%;
  background: url("../../assets/Group 14.png");
  background-size: 100% 100%;
  padding: 5rem 25rem 5rem 5rem;
  .left {
    .title {
      font-size: 1.25rem;
      color: #000000;
    }
    .content {
      margin: 1.25rem 5rem;
    }
  }
  .right {
    flex: 1;
    height: 100%;
    .image {
      width: 54.8125rem;
      height: 36.25rem;
    }
  }
}
</style>